<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <b-form>
          <div class="card card-custom">
            <div class="card-header flex-wrap border-0 pt-6 pb-0">
              <div class="card-title">
                <h3 class="card-label">
                  <i class="fas fa-filter"></i> Filtros
                </h3>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <label class="font-weight-bold">Reporte</label>
                  <b-form-input
                    id="input-1"
                    v-model="filtros.nombreReporte"
                    @keyup.enter="fetchReportes()"
                    placeholder="Buscar por nombre"
                  ></b-form-input>
                </div>
              </div>
            </div>
            <div class="card-footer padding-t-b">
              <div class="col-md-12">
                <a
                  href="#"
                  class="btn btn-primary font-weight-bold mr-2 float-right"
                  @click.prevent="fetchReportes()"
                  ><i class="fa fa-search"></i> Buscar</a
                >
              </div>
            </div>
          </div>
        </b-form>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom">
          <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label">
                <i class=" fa fa-chart-pie"></i> Gráficos Genéricos
              </h3>
            </div>
            <div class="card-toolbar">
              <a
                href="#"
                class="btn btn-primary font-weight-bold mr-2"
                @click="initCrearReporte"
                ><i class="la la-plus"></i> Nuevo</a
              >
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table
                class="table table-head-custom table-vertical-center table-head-bg table-hover"
              >
                <thead>
                  <tr>
                    <th>Reporte</th>
                    <th>Descripción</th>
                    <th>Fecha de modificación</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="reporte in reportes"
                    :key="reporte.reporteDimensionConfiguracionId"
                  >
                    <td>
                      {{ reporte.titulo }}<br />
                      <small class="text-muted"
                        >{{ reporte.seccion }} > {{ reporte.codigo }}</small
                      >
                    </td>
                    <td>
                      {{ reporte.descripcion }}
                    </td>
                    <td>
                      {{ reporte.modificacionFechaUtc | datetime }}
                    </td>
                    <td class="text-right">
                      <b-dropdown right>
                        <template #button-content>
                          <i class="fas fa-ellipsis-v"></i>
                        </template>
                        <b-dropdown-item
                          href="#"
                          @click.prevent="gotoViewReporte(reporte)"
                        >
                          <span class="navi-icon"
                            ><i class="fa fa-eye"></i> Apariencia</span
                          >
                        </b-dropdown-item>
                        <b-dropdown-item
                          href="#"
                          @click.prevent="gotoEditReporte(reporte)"
                        >
                          <span class="navi-icon"
                            ><i class="fa fa-pen"></i> Origen de datos</span
                          >
                        </b-dropdown-item>
                      </b-dropdown>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer">
            <b-pagination
              size="md"
              v-model="pagination.page"
              :total-rows="pagination.totalCount"
              :per-page="pagination.pageSize"
              hide-goto-end-buttons
              class="m-0"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import moment from "moment";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

/*intervalos de tiempo de Inactividad*/
import {
  INACTIVE_USER_TIME_THRESHOLD,
  USER_ACTIVITY_THROTTLER_TIME,
} from "@/helpers/constants.js";

export default {
  metaInfo: {
    title: "Reportes - Data",
  },
  data: () => ({
    moment: moment,
    pagination: { page: 1, paginate: true },
    reportes: [],
    filtros: {
      nombreReporte: null,
    },
    /*variables de inactividad*/
    isInactive: false,
    userActivityThrottlerTimeout: null,
    userActivityTimeout: null,
  }),
  methods: {
    initCrearReporte: function(dimension) {
      this.$router.push({
        name: "reports-data-edit",
        params: {},
      });
    },
    gotoEditReporte: function(reporte) {
      this.$router.push({
        name: "reports-data-edit",
        params: { reporteDataId: reporte.reporteDataId },
      });
    },
    gotoViewReporte: function(reporte) {
      this.$router.push({
        name: "reports-data-view",
        params: { reporteDataId: reporte.reporteDataId },
      });
    },
    fetchReportes: function() {
      this.showLoading();
      var model = this.cloneObject(this.filtros);
      model.pagination = this.pagination;
      this.$http
        .post(this.apiRoute("ReportData", "ListReportes"), model)
        .then(function(response) {
          if (response.body.error) {
            Swal.fire({
              icon: "error",
              title: "",
              text: response.body.message,
            });
          } else {
            this.reportes = response.body.data.items;
            this.pagination = response.body.data.pagination;
          }
          this.showLoading(false);
        });
    },
    /*Metodos para verificar la inactividad del usuario*/
    activateActivityTracker: function() {
      window.addEventListener("mousedown", this.userActivityThrottler);
      window.addEventListener("mousemove", this.userActivityThrottler);
      window.addEventListener("scroll", this.userActivityThrottler);
      window.addEventListener("keydown", this.userActivityThrottler);
      window.addEventListener("resize", this.userActivityThrottler);
      window.addEventListener("touchstart", this.userActivityThrottler); //para dispositivos moviles
    },
    deactivateActivityTracker: function() {
      window.removeEventListener("mousedown", this.userActivityThrottler);
      window.removeEventListener("mousemove", this.userActivityThrottler);
      window.removeEventListener("scroll", this.userActivityThrottler);
      window.removeEventListener("keydown", this.userActivityThrottler);
      window.removeEventListener("resize", this.userActivityThrottler);
      window.removeEventListener("touchstart", this.userActivityThrottler); //para dispositivos moviles
    },
    resetUserActivityTimeout: function() {
      clearTimeout(this.userActivityTimeout);

      this.userActivityTimeout = setTimeout(() => {
        this.userActivityThrottler();
        this.inactiveUserAction();
      }, INACTIVE_USER_TIME_THRESHOLD); /*=> INACTIVE_USER_TIME_THRESHOLD */
    },
    userActivityThrottler: function() {
      if (this.isInactive) {
        this.isInactive = false;
      }

      if (!this.userActivityThrottlerTimeout) {
        this.userActivityThrottlerTimeout = setTimeout(() => {
          this.resetUserActivityTimeout();
          clearTimeout(this.userActivityThrottlerTimeout);
          this.userActivityThrottlerTimeout = null;
        }, USER_ACTIVITY_THROTTLER_TIME); /*=> USER_ACTIVITY_THROTTLER_TIME*/
      }
    },
    inactiveUserAction: function() {
      this.isInactive = true;

      Swal.fire({
        icon: "warning",
        title: "Sesión Expirada",
        text: "Su sesión ha expirado. Por favor vuelva a iniciar sesión.",
        confirmButtonText: `Iniciar Sesión`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.deactivateActivityTracker();
          clearTimeout(this.userActivityTimeout);
          clearTimeout(this.userActivityThrottlerTimeout);
          this.$router.push({ name: "login" });
        }
      });
    },
  },
  watch: {
    "pagination.page": function(newValue, oldValue) {
      this.fetchReportes();
    },
  },
  mounted: function() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Gráficos Genéricos" }]);
    this.fetchReportes();
    this.activateActivityTracker();
  },
};
</script>
